<script lang="ts" setup>
import { computed } from 'vue'
import AnimatedArrow from './AnimatedArrow.vue'

const props = defineProps<{
  href: string
  text: string
}>()

const target = computed(() => {
  if (props.href.startsWith('https://www.cypress.io/')) {
    return undefined
  }

  if (props.href.startsWith('http')) {
    return '_blank'
  }

  return undefined
})
</script>

<template>
  <a :href="href" class="group card overflow-hidden shadow-marketing-card" :target="target">
    <slot name="image" />

    <div class="p-comfortable flex grow flex-col justify-between gap-[16px]">
      <h2
        class="line-clamp-3 overflow-hidden font-primary text-[20px] font-semibold leading-[32px] md:h-[96px] lg:h-[112px] lg:text-[24px] lg:leading-[36px]"
      >
        {{ text }}
      </h2>
      <slot name="post-title" />

      <span class="inline-flex items-center font-secondary font-medium text-indigo-500 md:mt-[24px] lg:mt-[32px]">
        Read more
        <AnimatedArrow />
      </span>
    </div>
  </a>
</template>
