<script lang="ts" setup>
import { isCypressOrg } from '@/stores'
import { IconObjectGear } from '@cypress-design/vue-icon'

const ROOT_EDIT_URL = `${import.meta.env.GHOST_API_URL}/ghost`
</script>

<template>
  <a
    v-if="isCypressOrg"
    class="absolute bottom-0 right-0 z-100 block w-[200px] px-[16px] py-[8px] text-center text-gray-500 transition-colors hover:text-indigo-500"
    :href="`${ROOT_EDIT_URL}/posts`"
  >
    <IconObjectGear class="mr-[4px] inline-block h-[16px] w-[16px] align-text-bottom" />
    Manage posts
  </a>
</template>
