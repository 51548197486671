<script lang="ts" setup>
import { ref } from 'vue'
import { AisHighlight, AisHits } from 'vue-instantsearch/vue3/es'
import AlgoliaInstantSearch from '@/components/Vue/AlgoliaInstantSearch.vue'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  year: 'numeric',
  day: 'numeric',
})

function formatDate(dateStr: string) {
  if (!dateStr) return
  const date = new Date(dateStr)
  return dateFormatter.format(date)
}

function metaInfoFilter(hit: any) {
  return [hit.author, hit.category, formatDate(hit.published_at)].filter(Boolean).join(' • ')
}

const numberOfItems = ref(0)

function transformItems(items: any[]) {
  numberOfItems.value = items.length
  return items.map((i) => ({ ...i, url: i.url.replace(/^https:\/\/www\.cypress\.io/, '') }))
}
</script>

<template>
  <AlgoliaInstantSearch index-name="cypressBlog" placeholder="Search the blog" class="mt-[32px]">
    <template #allHits="{ keyboardHighlighted }">
      <AisHits v-show="numberOfItems > 0" :transform-items="transformItems">
        <template #item="{ item: hit, index }">
          <hr class="mb-[8px]" />
          <a
            class="mb-[8px] block rounded p-[8px] pt-[6px] text-gray-800 hover:bg-indigo-50"
            :class="{
              'bg-indigo-50': keyboardHighlighted === index,
            }"
            :href="hit.url"
          >
            <h2 class="text-[16px] font-medium text-indigo-500">
              <AisHighlight attribute="post_title" :hit="hit" />
            </h2>
            <p class="text-gray-700">
              {{ metaInfoFilter(hit) }}
            </p>
          </a>
        </template>
      </AisHits>
    </template>
  </AlgoliaInstantSearch>
</template>

<style scoped>
::v-deep(.ais-Hits) {
  @apply absolute left-[-2px] right-[-2px] top-[-2px] z-10 overflow-auto rounded-3xl rounded-b-xl border border-indigo-500 bg-white px-[8px] pt-[50px] shadow-lg ring-2 ring-gray-400/25;
}
</style>
