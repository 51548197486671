<script lang="ts" setup>
import type { Component, SVGAttributes } from 'vue'
import type { PostOrPage } from '@tryghost/content-api'
import DefaultIcon from '../assets/Default.svg?component'
import EducationIcon from '../assets/Education.svg?component'
import CommunityIcon from '../assets/Community.svg?component'
import CompanyIcon from '../assets/Default.svg?component'
import ReleaseIcon from '../assets/Release.svg?component'

const MAP_CATEGORY_PLACEHOLDER: Record<string, Component<SVGAttributes>> = {
  Education: EducationIcon as Component<SVGAttributes>,
  Community: CommunityIcon as Component<SVGAttributes>,
  Company: CompanyIcon as Component<SVGAttributes>,
  Releases: ReleaseIcon as Component<SVGAttributes>,
  default: DefaultIcon as Component<SVGAttributes>,
}

function getPlaceholderByCategory(category: string) {
  return MAP_CATEGORY_PLACEHOLDER[category] ?? MAP_CATEGORY_PLACEHOLDER.default
}

defineProps<{
  cssClass?: string
  post: PostOrPage & { category: string }
}>()
</script>

<template>
  <img
    v-if="post.feature_image || post.og_image"
    :src="post.feature_image || post.og_image || ''"
    width="768"
    height="460"
    data-cy="blog-post-image"
    :alt="post.feature_image_alt ?? ''"
    :class="cssClass"
  />
  <div v-else :class="[cssClass, 'flex items-center overflow-clip']">
    <component :is="getPlaceholderByCategory(post.category)" width="768" height="460" class="h-auto w-auto" />
  </div>
</template>
