<script setup lang="ts">
import Button from '@cypress-design/vue-button'
import { computed } from 'vue'

defineProps<{
  page: number
  pageNumbers: number[]
}>()
</script>

<template>
  <div class="mx-auto flex flex-wrap justify-center gap-[16px]">
    <Button
      v-if="page > 1"
      variant="outline-light"
      :href="`/blog/page/${page - 1}`"
      class="text-indigo-400 hover:underline"
    >
      Newer posts
    </Button>

    <Button
      v-if="page < pageNumbers.length"
      variant="outline-light"
      :href="`/blog/page/${page + 1}`"
      class="text-indigo-400 hover:underline"
    >
      Older posts
    </Button>
  </div>
</template>
