<script lang="ts" setup>
import { onMounted } from 'vue'
import { fetchPayingUser } from '@/stores'
import type { PostOrPage } from '@tryghost/content-api'
import BlogPostImage from './BlogPostImage.vue'
import Card from '@/components/Vue/Card.vue'
import getSlug from '../getSlug'
import EditPostButton from '@/components/pages/blog/vue/EditPostButton/EditPostButton.vue'

const props = defineProps<{
  posts: (PostOrPage & { category: string })[]
  draft?: boolean
}>()

onMounted(async () => {
  await fetchPayingUser()
})
</script>

<template>
  <div class="grid grid-cols-1 gap-[32px] md:grid-cols-2 lg:grid-cols-3">
    <div v-for="post of posts" :key="post.slug" class="relative">
      <Card
        class="card-marketing flex flex-col p-0"
        :href="draft ? `/blog/preview?id=${post.id}` : `/blog/${getSlug(post.slug, post.published_at)}`"
        :text="post.title ?? ''"
        :sub-text="post.excerpt"
        data-cy="blog-post"
      >
        <template #image>
          <BlogPostImage css-class="block md:h-[200px] w-full object-cover object-center" :post="post" />
        </template>

        <template #post-title>
          <p class="line-clamp-4 text-gray-700">{{ post.excerpt }}</p>
        </template>
      </Card>
      <EditPostButton class="absolute bottom-[24px] right-[16px]" :post-id="post.id" />
    </div>
  </div>
</template>
